import { useEffect, useRef, useState } from 'react';

import proto from '~/proto/messages_pb';
import { Sport } from '~api/category/types';
import {
  InPlayMenuSports,
  TopPrematchDateGroup,
  TopPrematchEvent,
  TopPrematchEvents,
} from '~api/sportEvent/types';
import { prepareRespData } from '~components/molecules/LiveMenu/utils/eventsParsing';
import { processData } from '~components/molecules/PrematchEvents/helpers/extractData';
import { QUERY_PARAMS } from '~constants/common';
import { setCounts } from '~features/sportsMenu/sportsMenuSlice';
import { useQueryParams } from '~hooks/useQueryParams';
import { useRouterQuery } from '~hooks/useRouterQuery';
import {
  getProtoCountsData,
  getProtoInPlayData,
  getProtoInPlayEventData,
  getProtoInPlayEventsByMarketData,
  getProtoMainMarketsData,
  getProtoPrematchData,
  getProtoPrematchEventData,
  getProtoPrematchEventsByLeagueData,
  getProtoPrematchSubMenuData,
  getProtoTopGamesData,
  getProtoTopTournamentsData,
  getProtoUpcomingData,
} from '~proto/protoMiddleware';
import {
  prepareTopTournamentsData,
  prepareUpcomingSportsOrTopGamesData,
} from '~proto/utils';
import { useAppDispatch, useAppSelector } from '~store';
import {
  addEventsWithMarkets,
  replaceEvents,
  rewriteEvent,
} from '~store/slices/eventsSlice';
import {
  resetAddedLeagueId,
  resetAddedSportId,
  setDefaultMainMarketsSelected,
  setIsEventLoaded,
  setIsLiveMenuLoaded,
  setIsLiveMenuLoading,
  setLiveEventData,
  setLiveMenuSports,
  setLoadingEventId,
  setLoadingSportId,
  setMarketGroups,
  setWidgetKey,
} from '~store/slices/liveMenuSlice';
import { setMainMarkets } from '~store/slices/mainMarketsSlice';
import {
  setActiveMainHighlightsSportId,
  setCountriesData,
  setIsAllLoaded,
  setIsLoading,
  setLeagueEvents,
  setLeaguesData,
  setLoadByDate,
  setOpenedCountries,
  setOpenedSports,
  setSelectedLeagueData,
  setSports,
  setUpcomingEvents,
} from '~store/slices/prematchMenuSlice';
import { setPrimaryDataLoaded } from '~store/slices/signalRSocketsSlice';
import {
  setIsTopSportEventsLoaded,
  setIsTopTournamentsLoaded,
  setTopSportEvents,
  setTopTournaments,
} from '~store/slices/sportGroupsSlice';
import { SportEvent } from '~types/events';
import { ACTION_TYPE } from '~utils/eventsSocketUtils';
import { groupByMarketId } from '~utils/markets';
import { isDebugMode, QueryParams } from '~utils/url';

const limit = 20;
const isJson =
  isDebugMode() || import.meta.env.VITE_WEBSOCKET_IS_JSON === 'true';

export const useListenEventsLoadingSocket = () => {
  const dispatch = useAppDispatch();
  const { updateQueryParams } = useRouterQuery();
  const { eventsSocket, eventSocketConnected, primaryDataLoaded } =
    useAppSelector((state) => state.signalRSockets);
  const { liveMenuSports } = useAppSelector((state) => state.liveMenu);
  const { mainMarkets } = useAppSelector((state) => state.mainMarkets);
  const mainMarketsRef = useRef(mainMarkets);
  const { upcomingEvents, countriesData, loadingCountryId, loadingSportId } =
    useAppSelector((state) => state.prematchMenu);
  const upcomingEventsRef = useRef(upcomingEvents);
  const queryParams = useQueryParams();
  const queryParamsRef = useRef(queryParams);
  const { dateToFilter, sports } = useAppSelector(
    (state) => state.prematchMenu,
  );
  const loadByDateRef = useRef(dateToFilter);
  const prevLoadByRef = useRef(dateToFilter);
  const sportsRef = useRef(sports);
  const loadingCountryIdRef = useRef(loadingCountryId);
  const loadingSportIdRef = useRef<number>(loadingSportId as number);
  const countriesDataRef = useRef(countriesData);
  const liveMenuSportsRef = useRef(liveMenuSports);
  const [isMarketLoaded, setIsMarketLoaded] = useState(false);
  const [isCountLoaded, setIsCountLoaded] = useState(false);

  // Track message processing
  const processingMessages = useRef(false);
  const messageQueue = useRef<unknown[]>([]);

  useEffect(() => {
    if (!primaryDataLoaded) {
      setIsCountLoaded(false);
      setIsMarketLoaded(false);
    }
  }, [primaryDataLoaded]);

  useEffect(() => {
    const primaryDataLoaded = isMarketLoaded && isCountLoaded;

    dispatch(setPrimaryDataLoaded(primaryDataLoaded));
  }, [isMarketLoaded, isCountLoaded]);

  useEffect(() => {
    liveMenuSportsRef.current = liveMenuSports;
  }, [liveMenuSports]);

  useEffect(() => {
    countriesDataRef.current = countriesData;
  }, [countriesData]);

  useEffect(() => {
    mainMarketsRef.current = mainMarkets;
  }, [mainMarkets]);

  useEffect(() => {
    prevLoadByRef.current = loadByDateRef.current;
    loadByDateRef.current = dateToFilter;
  }, [dateToFilter]);

  useEffect(() => {
    queryParamsRef.current = queryParams;
  }, [queryParams]);

  useEffect(() => {
    sportsRef.current = sports;
  }, [sports]);

  useEffect(() => {
    upcomingEventsRef.current = upcomingEvents;
  }, [upcomingEvents]);

  useEffect(() => {
    loadingCountryIdRef.current = loadingCountryId;
  }, [loadingCountryId]);

  useEffect(() => {
    loadingSportIdRef.current = loadingSportId as number;
  }, [loadingSportId]);

  useEffect(() => {
    if (!eventsSocket || !eventSocketConnected) return;

    const handleSocketMessage = async (evt: unknown) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      let messageType;
      let messageData;

      if (isJson) {
        const { type: jsonMessageType, data: jsonMessageData } = JSON.parse(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          evt.data,
        );

        messageType = jsonMessageType;
        messageData = jsonMessageData;
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const _data = await evt.data.arrayBuffer();
        const _array = new Uint8Array(_data);

        const { type: protoType, data: protoData } =
          proto.UpdateMessage.deserializeBinary(_array).toObject();

        messageType = protoType;
        messageData = protoData;
      }

      const actionType = messageType as ACTION_TYPE;

      switch (actionType) {
        case ACTION_TYPE.GET_IN_PLAY:
          const inPlayData = isJson
            ? prepareRespData(messageData.events)
            : getProtoInPlayData(messageData);

          dispatch(setLiveMenuSports(inPlayData as InPlayMenuSports));
          dispatch(setIsLiveMenuLoaded(true));

          break;
        case ACTION_TYPE.GET_UPCOMING:
          const upcomingSports = isJson
            ? prepareUpcomingSportsOrTopGamesData(
                messageData.sports,
                messageData.categories,
                true,
              )
            : getProtoUpcomingData(messageData);

          const isAnyUpcomingLoaded = !!upcomingEventsRef.current?.length;

          if (isAnyUpcomingLoaded) {
            const { sportId: loadedSportId, dateGroups: dateGroupsLoaded } =
              upcomingSports.find(
                ({ dateGroups }) => dateGroups.length,
              ) as TopPrematchEvent;

            const previouslyLoadedData =
              upcomingEventsRef.current as TopPrematchEvents;

            const groupsAlreadyExists = !!previouslyLoadedData.find(
              ({ sportId }) => sportId === loadedSportId,
            )?.dateGroups.length;

            if (groupsAlreadyExists) {
              const totalEventsLoaded = dateGroupsLoaded.reduce(
                (acc, { matches }) => acc + matches.length,
                0,
              );

              if (totalEventsLoaded < limit) {
                dispatch(setIsAllLoaded(true));
              }

              const updatedData = previouslyLoadedData.map((sportData) => ({
                ...sportData,
                dateGroups: sportData.dateGroups.map((group) => ({
                  ...group,
                  matches: [...group.matches],
                })),
              }));

              const sportDataIndex = updatedData.findIndex(
                (sportData) => sportData.sportId === loadedSportId,
              );

              if (sportDataIndex !== -1) {
                const updatingData = updatedData[
                  sportDataIndex
                ] as TopPrematchEvent;

                dateGroupsLoaded.forEach(({ matches, date }) => {
                  const foundDateGroupIndex = updatingData.dateGroups.findIndex(
                    (group) => group.date === date,
                  );

                  if (foundDateGroupIndex !== -1) {
                    const foundDateGroup = {
                      ...updatingData.dateGroups[foundDateGroupIndex],
                    } as TopPrematchDateGroup;

                    foundDateGroup.matches = [
                      ...(foundDateGroup.matches as SportEvent[]),
                      ...matches,
                    ];
                    updatingData.dateGroups[foundDateGroupIndex] =
                      foundDateGroup;
                  } else {
                    updatingData.dateGroups.push({
                      date,
                      matches,
                    });
                  }
                });
              }

              dispatch(
                setActiveMainHighlightsSportId(loadedSportId.toString()),
              );
              dispatch(setUpcomingEvents(updatedData));

              return;
            } else {
              const preparedData = upcomingEventsRef.current?.map(
                ({ sportId: currentSportId, dateGroups, ...rest }) => {
                  if (currentSportId === loadedSportId) {
                    return {
                      ...rest,
                      sportId: currentSportId,
                      dateGroups: dateGroupsLoaded,
                    };
                  }

                  return {
                    ...rest,
                    sportId: currentSportId,
                    dateGroups,
                  };
                },
              );

              dispatch(setUpcomingEvents(preparedData as TopPrematchEvents));
            }
          } else {
            dispatch(setUpcomingEvents(upcomingSports));
          }

          break;
        case ACTION_TYPE.GET_MAIN_MARKETS:
          setIsMarketLoaded(true);
          const { mainMarkets, defaultSelectedMainMarkets } = isJson
            ? {
                mainMarkets: messageData.sports,
                defaultSelectedMainMarkets: messageData.sports.reduce(
                  (acc: unknown, sport: unknown) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    acc[sport.id] = sport.markets[0]?.id || null;

                    return acc;
                  },
                  {},
                ),
              }
            : getProtoMainMarketsData(messageData);

          dispatch(setMainMarkets(mainMarkets));
          dispatch(setDefaultMainMarketsSelected(defaultSelectedMainMarkets));

          break;
        case ACTION_TYPE.GET_PREMATCH:
          const sportsMessage = isJson
            ? (messageData.sports as Sport[])
            : getProtoPrematchData(messageData);

          let sportId = queryParamsRef.current.sportId;

          const countryId = queryParamsRef.current.countryId;

          const group = queryParamsRef.current.group;

          const currentLoadBy = loadByDateRef.current;
          const prevLoadBy = prevLoadByRef.current;

          const isLoadByReseted = !currentLoadBy && !!prevLoadBy;
          const isLoadByChanged = prevLoadBy !== currentLoadBy;

          if (isLoadByChanged || isLoadByReseted) {
            dispatch(setSports(sportsMessage));
            dispatch(setLoadByDate(false));
            const sportWithCountries = sportsMessage.find(
              (sport) => sport.countries.length,
            );

            const sportBySportId =
              sportsMessage.find((sport) => sport.id === Number(sportId)) ||
              sportWithCountries;

            if (sportBySportId?.id !== Number(sportId)) {
              updateQueryParams({
                sportId: sportBySportId?.id.toString(),
              } as QueryParams<typeof QUERY_PARAMS>);
            }

            const isSelectedCountryInSport = sportBySportId?.countries.find(
              (country) => country.id === Number(countryId),
            );
            const country =
              isSelectedCountryInSport && countryId
                ? countryId
                : sportBySportId?.countries[0]?.id.toString();

            dispatch(
              setOpenedSports([
                sportId || (sportWithCountries?.id.toString() as string),
              ]),
            );
            dispatch(setOpenedCountries([country as string]));
            dispatch(setIsLoading(false));

            return;
          }

          if (!sportsRef.current?.length) {
            dispatch(setSports(sportsMessage));
            const { id: sportId, countries } =
              sportsMessage.find((item) => item.countries.length > 0) || {};

            dispatch(setOpenedSports([sportId?.toString() || '']));
            if (countries && countries.length && !group) {
              dispatch(
                setOpenedCountries([
                  countryId || countries[0]?.id.toString() || '',
                ]),
              );
            }

            dispatch(setIsLoading(false));

            return;
          }

          let sportData: Sport;

          if (!sportId) {
            sportData = sportsMessage[0] as Sport;
            sportId = sportData.id.toString();
          } else {
            sportData = sportsMessage.find(
              (sport) => sport.id === Number(sportId),
            ) as Sport;
          }

          const sportsCountries = sportData?.countries || [];

          sportsCountries.forEach(({ id, leagues }) => {
            if (leagues.length) {
              dispatch(setLeaguesData({ id: id.toString(), data: leagues }));
            }
          });

          dispatch(
            setOpenedCountries([sportsCountries[0]?.id.toString() || '']),
          );

          const currentParams = queryParamsRef.current;

          if (currentParams.eventId) {
            dispatch(setIsLoading(false));

            return;
          }

          const resSports = sportsRef.current.map((sport) => {
            if (sport.id === Number(sportId)) {
              return {
                ...sport,
                countries: sportsCountries || [],
              };
            }

            return sport;
          });

          dispatch(setSports(resSports));
          dispatch(setIsLoading(false));
          break;
        case ACTION_TYPE.GET_TOP_TOURNAMENTS:
          const topTournaments = isJson
            ? prepareTopTournamentsData(messageData.tournaments)
            : getProtoTopTournamentsData(messageData);

          if (topTournaments?.length) {
            dispatch(setTopTournaments(topTournaments));
            dispatch(setIsTopTournamentsLoaded(true));
          }

          break;
        case ACTION_TYPE.GET_TOP_GAMES:
          const topGames = isJson
            ? prepareUpcomingSportsOrTopGamesData(
                messageData.sports,
                messageData.categories,
                true,
              )
            : getProtoTopGamesData(messageData);

          if (topGames?.length) {
            dispatch(setTopSportEvents(topGames));
          }

          dispatch(setIsTopSportEventsLoaded(true));

          break;
        case ACTION_TYPE.GET_PREMATCH_EVENT:
          const parsedPrematchEvent = isJson
            ? messageData
            : getProtoPrematchEventData(messageData);

          if (!parsedPrematchEvent) {
            const paramsCopy = { ...queryParamsRef.current };

            delete paramsCopy.eventId;
            updateQueryParams(
              paramsCopy as QueryParams<typeof QUERY_PARAMS>,
              true,
            );

            return;
          }

          const { markets: prematchMarkets } =
            parsedPrematchEvent as SportEvent;

          dispatch(setWidgetKey(null));
          dispatch(setLiveEventData(parsedPrematchEvent));
          dispatch(setMarketGroups(groupByMarketId(prematchMarkets)));
          dispatch(rewriteEvent(parsedPrematchEvent));
          setTimeout(() => {
            dispatch(setLoadingEventId(null));
            dispatch(setIsEventLoaded(true));
          }, 100);
          break;
        case ACTION_TYPE.GET_IN_PLAY_EVENT:
          const parsedInPlayEvent = isJson
            ? messageData
            : getProtoInPlayEventData(messageData);

          if (!parsedInPlayEvent) {
            const paramsCopy = { ...queryParamsRef.current };

            delete paramsCopy.eventId;
            updateQueryParams(
              paramsCopy as QueryParams<typeof QUERY_PARAMS>,
              true,
            );

            return;
          }

          const { markets } = parsedInPlayEvent as SportEvent;

          dispatch(setLiveEventData(parsedInPlayEvent));
          dispatch(setMarketGroups(groupByMarketId(markets)));
          setTimeout(() => {
            dispatch(setLoadingEventId(null));
            dispatch(setIsEventLoaded(true));
          }, 100);
          break;
        case ACTION_TYPE.GET_COUNTS:
          setIsCountLoaded(true);
          const countsData = isJson
            ? { inplay: messageData.inPlay, prematch: messageData.preMatch }
            : getProtoCountsData(messageData);

          dispatch(setCounts(countsData));
          break;
        case ACTION_TYPE.GET_IN_PLAY_EVENTS_BY_MARKET_ID:
          const inPlayEventsByMarketData = isJson
            ? messageData.events
            : getProtoInPlayEventsByMarketData(messageData);

          const liveMenuSportsData = liveMenuSportsRef.current;

          if (liveMenuSportsData) {
            const resultInPlayMenu = liveMenuSportsData.map(
              ({ countries, ...rest1 }) => {
                return {
                  ...rest1,
                  countries: countries.map(({ leagues, ...rest2 }) => {
                    return {
                      ...rest2,
                      leagues: leagues.map(({ events, ...rest3 }) => {
                        return {
                          ...rest3,
                          events: events.map((event) => {
                            const updatedEvent = inPlayEventsByMarketData.find(
                              (evt: SportEvent) => evt.id === event.id,
                            );

                            if (updatedEvent) {
                              return {
                                ...event,
                                ...updatedEvent,
                              };
                            }

                            return event;
                          }),
                        };
                      }),
                    };
                  }),
                };
              },
            );

            dispatch(setLiveMenuSports(resultInPlayMenu));
          }

          dispatch(replaceEvents(inPlayEventsByMarketData));
          setTimeout(() => {
            dispatch(resetAddedLeagueId());
            dispatch(resetAddedSportId());
            dispatch(setLoadingSportId(null));
            dispatch(setIsLiveMenuLoading(false));
          }, 100);

          break;
        case ACTION_TYPE.GET_PREMATCH_EVENTS_BY_LEAGUE:
          const { data } = isJson
            ? {
                data: messageData.data,
              }
            : getProtoPrematchEventsByLeagueData(messageData);

          if (
            data &&
            mainMarketsRef.current.length &&
            queryParamsRef.current.sportId
          ) {
            const { preparedData, allEvents, allMarkets } = processData(
              data,
              mainMarketsRef.current,
              parseInt(queryParamsRef.current.sportId),
            );

            dispatch(
              addEventsWithMarkets({ events: allEvents, markets: allMarkets }),
            );

            dispatch(setSelectedLeagueData(data));
            dispatch(setLeagueEvents(preparedData));
          }

          break;
        case ACTION_TYPE.GET_PREMATCH_SUB_MENU:
          const countryParam = loadingCountryIdRef.current;
          const sportParam = loadingSportIdRef.current;
          const prematchData = sportsRef.current;

          const prematchSubMenuData = isJson
            ? messageData.categories
            : getProtoPrematchSubMenuData(messageData);

          if (!countryParam) {
            dispatch(
              setCountriesData({
                ...countriesDataRef.current,
                [sportParam]: prematchSubMenuData,
              }),
            );

            return;
          }

          if (sportParam && prematchData && countryParam) {
            const resSports = prematchData.map((sport) => {
              if (sport.id === sportParam) {
                return {
                  ...sport,
                  countries: sport.countries.map((country) => {
                    if (country.id === countryParam) {
                      return {
                        ...country,
                        data: prematchSubMenuData,
                      };
                    }

                    return country;
                  }),
                };
              }

              return sport;
            });

            dispatch(setSports(resSports));
            dispatch(
              setLeaguesData({
                id: countryParam.toString() || '',
                data: prematchSubMenuData,
              }),
            );
          }

          break;

        default:
          break;
      }
    };

    const processMessageQueue = async () => {
      if (processingMessages.current || !messageQueue.current.length) return;

      processingMessages.current = true;
      while (messageQueue.current.length) {
        const message = messageQueue.current.shift();

        if (message) await handleSocketMessage(message);
      }

      processingMessages.current = false;
    };

    eventsSocket.onmessage = (evt) => {
      messageQueue.current.push(evt);
      processMessageQueue();
    };

    return () => {
      if (eventsSocket) eventsSocket.onmessage = null;
    };
  }, [eventsSocket, eventSocketConnected]);
};
