import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  AUTH_FIELD_TYPE,
  PaymentSettings,
  SportsBettingConfig,
  WebsiteSettingsResponse,
} from '~api/partner/types';
import { SUPPORTED_COUNTRY_CODE } from '~components/molecules/Authentication/types';
import { ODD_ASK_TYPES } from '~components/molecules/Betslip/constants';
import { CURRENCY_CODES, LEGAL_AGE } from '~constants/common';

export type SettingsState = WebsiteSettingsResponse;

const initialState: SettingsState = {
  authBy: '',
  quickBet: 0,
  defaultOddPolicyAcceptance: ODD_ASK_TYPES?.ALL,
  currency: null as unknown as CURRENCY_CODES,
  minimumAge: LEGAL_AGE,
  mobileSettings: [],
  maxWinAmount: 0,
  separateBonusBalance: false,
  paymentProviderSettings: [],
  allowSystemBets: false,
  paymentSettings: {
    withdrawalLimitMin: 0,
    withdrawalLimitMax: 0,
    favoriteWithdrawAmounts: [],
    depositLimitMin: 0,
    depositLimitMax: 0,
    favoriteDepositAmounts: [],
  },
  maxStake: 0,
  passwordRequirement: {
    partnerId: '',
    minLength: 0,
    maxLength: 0,
    requireSymbol: false,
    requireNumber: false,
    requireUpperCase: false,
    requireLowerCase: false,
    allowNumber: false,
    allowSymbol: false,
    allowUpperCase: false,
    allowLowerCase: false,
    allowedCharacters: 15,
  },
  authField: AUTH_FIELD_TYPE.USER_NAME,
  partnerLimits: {
    sportMinSelectionCount: 0,
    sportMaxSelectionCount: 100,
    sportMinStake: 50,
    sportMaxStake: 1000000,
  },
  enableDecimals: false,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setWebsiteSettings: (
      state,
      action: PayloadAction<WebsiteSettingsResponse>,
    ) => {
      const {
        authBy,
        mobileSettings,
        minimumAge,
        defaultOddPolicyAcceptance,
        passwordRequirement,
        authField,
        maxWinAmount,
        quickBet,
        paymentSettings,
        maxStake,
        partnerLimits,
        separateBonusBalance,
        allowSystemBets,
        siteConfiguration,
      } = action.payload;

      const currencyCountryMap: {
        [key in SUPPORTED_COUNTRY_CODE]: CURRENCY_CODES;
      } = {
        [SUPPORTED_COUNTRY_CODE.NGN]: CURRENCY_CODES.NGN,
        [SUPPORTED_COUNTRY_CODE.UGX]: CURRENCY_CODES.UGX,
        [SUPPORTED_COUNTRY_CODE.MZ]: CURRENCY_CODES.MZ,
        [SUPPORTED_COUNTRY_CODE.UA]: CURRENCY_CODES.UA,
        [SUPPORTED_COUNTRY_CODE.TZ]: CURRENCY_CODES.TZ,
        [SUPPORTED_COUNTRY_CODE.BR]: CURRENCY_CODES.BRL,
        [SUPPORTED_COUNTRY_CODE.IN]: CURRENCY_CODES.INR,
      };

      if (partnerLimits) {
        state.partnerLimits = partnerLimits;
      }

      state.quickBet = quickBet;
      state.maxWinAmount = maxWinAmount;
      state.maxStake = maxStake;
      state.defaultOddPolicyAcceptance = defaultOddPolicyAcceptance;
      state.authBy = authBy;
      state.mobileSettings = mobileSettings;
      state.paymentSettings = paymentSettings;
      state.minimumAge = minimumAge || LEGAL_AGE;
      state.passwordRequirement = passwordRequirement;
      state.separateBonusBalance = separateBonusBalance;
      state.allowSystemBets = allowSystemBets;
      state.siteConfiguration = siteConfiguration;
      const { countryAlpha2Code } = mobileSettings[0] || {};

      if (countryAlpha2Code) {
        state.currency =
          currencyCountryMap[countryAlpha2Code as SUPPORTED_COUNTRY_CODE];
      }

      state.authField = authField;
    },
    updateWebsiteSettings: (
      state,
      action: PayloadAction<{
        otherSettings: Partial<WebsiteSettingsResponse>;
        newBetsConfig: Partial<SportsBettingConfig>;
        newPaymentSettings: Partial<PaymentSettings>;
      }>,
    ) => {
      state.paymentSettings = {
        ...state.paymentSettings,
        ...action.payload.newPaymentSettings,
      };
      state.partnerLimits = {
        ...state.partnerLimits,
        ...action.payload.newBetsConfig,
      };

      const otherSettingsKeys = Object.keys(action.payload.otherSettings);

      otherSettingsKeys.forEach((key) => {
        // eslint-disable-next-line
        // @ts-ignore
        state[key] = action.payload.otherSettings[key];
      });
    },
  },
});

export const selectGamesState = (state: { settings: SettingsState }) =>
  state.settings;

export const { setWebsiteSettings, updateWebsiteSettings } =
  settingsSlice.actions;

export default settingsSlice.reducer;
