import dayjs from 'dayjs';

import { Country, Sport } from '~api/category/types';
import {
  MainMarket,
  MainMarketSelection,
  SportMainMarket,
} from '~api/market/types';
import {
  InPlayMenuCountry,
  InPlayMenuLeague,
  InPlayMenuSport,
  Market,
  Selection,
  SportEventItem,
  TopPrematchEvents,
  Tournament,
} from '~api/sportEvent/types';
import { EVENT_STATUS, SELECTION_STATUS } from '~constants/common';
import { SportEvent } from '~types/events';
import { Category } from '~utils/eventsSocketUtils';
import { formatDateWithCustomTimezone } from '~utils/formatDateTime';

const getDateGroups = (matches: ProtoTopMatch[]) => {
  return matches.reduce(
    (acc, match) => {
      const {
        startdate,
        status,
        marketsList,
        marketscount,
        leagueid,
        countryid,
        ...rest
      } = match;
      const dateString = dayjs(formatDateWithCustomTimezone(startdate)).format(
        'YYYY-MM-DD',
      );

      const objDateStr = new Date(dateString).toISOString();
      const foundDateGroup = acc.find((group) => group.date === objDateStr);
      const _match: SportEvent = {
        ...rest,
        status: status as EVENT_STATUS,
        startDate: startdate,
        markets: prepareEventMarketsData(marketsList) as Market[],
        marketsCount: marketscount,
        leagueId: leagueid,
        countryId: countryid,
      };

      if (foundDateGroup) {
        foundDateGroup.matches.push(_match);
      } else {
        acc.push({
          date: objDateStr,
          matches: [_match],
        });
      }

      return acc;
    },
    [] as { date: string; matches: SportEvent[] }[],
  );
};

const prepareEventSelectionData = (
  selectionsList: ProtoEventSelection[],
): Selection[] => {
  return selectionsList.map((selection) => {
    const { selectionid, status, ...rest } = selection;

    return {
      ...rest,
      selectionId: selectionid,
      status: status as SELECTION_STATUS,
    };
  });
};

const prepareEventMarketsData = (
  marketsList: ProtoEventMarket[],
): Omit<Market, 'argument'>[] => {
  return marketsList.map((market) => {
    const {
      marketid,
      orderid,
      selectionsList,
      eventid,
      marketgroupidsList,
      ...rest
    } = market;

    return {
      ...rest,
      marketGroupIds: marketgroupidsList,
      marketId: marketid,
      eventId: eventid,
      orderId: orderid,
      selections: prepareEventSelectionData(selectionsList),
    };
  });
};

const prepareInPlayEventsData = (
  eventsList: ProtoInPlayMenuEvent[],
): Omit<
  SportEventItem,
  | 'sportId'
  | 'sportName'
  | 'countryId'
  | 'countryName'
  | 'leagueId'
  | 'leagueName'
  | 'status'
  | 'livescore'
  | 'argument'
  | 'markets'
>[] => {
  return eventsList.map((event) => {
    const { extradata, marketscount, startdate, marketsList, ...rest } = event;

    const markets = prepareEventMarketsData(marketsList);

    return {
      ...rest,
      extraData: extradata,
      marketsCount: marketscount,
      startDate: startdate,
      markets,
    };
  });
};

const prepareInPlayLeaguesData = (
  leaguesList: ProtoInPlayMenuLeague[],
): Omit<InPlayMenuLeague, 'events'>[] => {
  return leaguesList.map((league) => {
    const { eventsList, ...rest } = league;

    const events = prepareInPlayEventsData(eventsList);

    return { ...rest, events };
  });
};

const prepareInPlayCountriesData = (
  countriesList: ProtoInPlayMenuCountry[],
): Omit<InPlayMenuCountry, 'leagues'>[] => {
  return countriesList.map((country) => {
    const { leaguesList, ...rest } = country;

    const leagues = prepareInPlayLeaguesData(leaguesList);

    return { ...rest, leagues };
  });
};

export const prepareInPlaySportsData = (
  eventsList: ProtoInPlayMenuSport[],
): Omit<InPlayMenuSport, 'countries'>[] => {
  return eventsList.map((event) => {
    const { countriesList, ...rest } = event;

    const countries = prepareInPlayCountriesData(countriesList);

    return {
      ...rest,
      countries,
    };
  });
};

const prepareMainMarketsSelectionData = (
  selectionsList: ProtoMainMarketSelection[],
): Omit<MainMarketSelection, 'selectionId' | 'odd'>[] => {
  return selectionsList.map((selection) => {
    return {
      id: selection.id,
      name: selection.name,
    };
  });
};

const prepareMainMarketsMarketData = (
  marketsList: ProtoMainMarket[],
): Omit<MainMarket, 'selections'>[] => {
  return marketsList.map((market) => {
    const { selectionsList, withline, shortname, ...rest } = market;

    return {
      ...rest,
      withLine: withline,
      shortName: shortname,
      selections: prepareMainMarketsSelectionData(selectionsList),
    };
  });
};

export const prepareMainMarketsSportsData = (
  sportsList: ProtoSportMainMarkets[],
): {
  mainMarkets: SportMainMarket[];
  defaultSelectedMainMarkets: Record<number, number>;
} => {
  const defaultSelectedMainMarkets: Record<number, number> = {};

  const mainMarkets = sportsList.map((sport) => {
    const { marketsList, id, ...rest } = sport;
    const markets = prepareMainMarketsMarketData(marketsList);

    const marketId = markets[0]?.id || null;

    if (marketId) {
      defaultSelectedMainMarkets[id] = marketId;
    }

    return {
      ...rest,
      id,
      markets,
    };
  }) as SportMainMarket[];

  return {
    mainMarkets,
    defaultSelectedMainMarkets,
  };
};

const preparePrematchSportsCountries = (
  countriesList: ProtoPrematchMenuCountry[],
): Omit<Country, 'leagues'>[] => {
  return countriesList.map((country) => {
    const { leaguesList, ...rest } = country;

    return {
      ...rest,
      leagues: leaguesList,
    };
  });
};

export const preparePrematchSportsData = (
  sportsList: ProtoPrematchMenuSport[],
): Omit<Sport, 'countries'>[] => {
  return sportsList.map((sport) => {
    const { countriesList, ...rest } = sport;

    return {
      ...rest,
      countries: preparePrematchSportsCountries(countriesList),
    };
  });
};

export const getJsonDateGroups = (matches: SportEvent[]) => {
  return matches.reduce(
    (acc, match) => {
      const { startDate } = match;
      const dateString = dayjs(formatDateWithCustomTimezone(startDate)).format(
        'YYYY-MM-DD',
      );

      const objDateStr = new Date(dateString).toISOString();

      const foundDateGroup = acc.find((group) => group.date === objDateStr);

      if (foundDateGroup) {
        foundDateGroup.matches.push(match);
      } else {
        acc.push({
          date: objDateStr,
          matches: [match],
        });
      }

      return acc;
    },
    [] as { date: string; matches: SportEvent[] }[],
  );
};

export const prepareUpcomingSportsOrTopGamesData = (
  sportsList: ProtoSportGroupPrematch<SportEvent>[],
  categoriesMap: Record<number, { name: string; code: string }>,
  isJson?: boolean,
): TopPrematchEvents => {
  return sportsList.map(({ matchesList, matches, id, ...rest }) => {
    const { name } = categoriesMap[id as number] as Category;

    const dateGroups = isJson
      ? getJsonDateGroups(matches)
      : getDateGroups(matchesList);

    return {
      ...rest,
      sportName: name,
      sportId: id as number,
      dateGroups: dateGroups.map(({ date, matches }) => {
        return {
          date,
          matches: matches.map(({ countryId, leagueId, ...rest }) => {
            const { name: countryName, code } =
              categoriesMap[countryId as number] || ({} as Category);
            const { name: leagueName } =
              categoriesMap[leagueId as number] || ({} as Category);

            return {
              ...rest,
              countryId,
              code,
              countryName,
              leagueName,
            };
          }),
        };
      }),
    };
  });
};

export const prepareTopTournamentsData = (
  tournamentsList: ProtoFavoriteTournament[],
): Tournament[] => {
  return tournamentsList.map((tournament) => {
    const { countryid, countrycode, countryname, sportid, sportname, ...rest } =
      tournament;

    return {
      countryId: countryid,
      countryCode: countrycode,
      countryName: countryname,
      sportId: sportid,
      sportName: sportname,
      ...rest,
    };
  });
};

export const prepareEventData = (event: ProtoEvent): SportEvent => {
  const {
    status,
    marketsList,
    sportid,
    sportname,
    countryid,
    countryname,
    leagueid,
    leaguename,
    marketscount,
    extradata,
    marketgroupsList,
    startdate,
    ...rest
  } = event;

  return {
    ...rest,
    status: status as EVENT_STATUS,
    sportId: sportid,
    sportName: sportname,
    countryId: countryid,
    countryName: countryname,
    leagueId: leagueid,
    leagueName: leaguename,
    marketsCount: marketscount,
    extraData: extradata,
    markets: prepareEventMarketsData(marketsList) as Market[],
    marketGroups: marketgroupsList,
    startDate: startdate,
  };
};

export const prepareInPlayEventsByMarketData = (
  eventsList: ProtoEventWithMainMarkets[],
): Omit<SportEvent, 'markets'>[] => {
  return eventsList.map((event) => {
    const { marketsList, extradata, marketscount, startdate, ...rest } = event;

    return {
      ...rest,
      extraData: extradata,
      marketsCount: marketscount,
      startDate: startdate,
      markets: prepareEventMarketsData(marketsList),
    };
  });
};

const preparePrematchEventsByLeagueLeagues = (
  leaguesList: ProtoPrematchLeague[],
): Omit<InPlayMenuLeague, 'events'>[] => {
  return leaguesList.map((league) => {
    const { eventsList, ...rest } = league;

    return {
      ...rest,
      events: prepareInPlayEventsByMarketData(eventsList),
    };
  });
};

const preparePrematchEventsByLeagueCountries = (
  countriesList: ProtoPrematchCountry[],
): Omit<InPlayMenuCountry, 'leagues'>[] => {
  return countriesList.map((country) => {
    const { leaguesList, ...rest } = country;

    return {
      ...rest,
      leagues: preparePrematchEventsByLeagueLeagues(leaguesList),
    };
  });
};

export const preparePrematchEventsByLeagueData = (
  dataList: ProtoPrematchSport[],
): Omit<InPlayMenuSport, 'countries'>[] => {
  return dataList.map((data) => {
    const { countriesList, ...rest } = data;

    return {
      ...rest,
      countries: preparePrematchEventsByLeagueCountries(countriesList),
    };
  });
};
