import React, { memo } from 'react';

import { BetslipHistoryItems } from '~api/betslip/types';
import { Box } from '~components/atoms/Box';
import { Loader } from '~components/atoms/Loader';
import { Text } from '~components/atoms/Typography';
import { NoBets } from '~components/molecules/BetslipHistorySection/NoBets';
import { useTranslation } from '~hooks/useTranslation';

import { BetslipHistoryMobileContent } from '../BetslipHistoryMobileContent';

interface BetslipHistoryMobileProps {
  isLoading: boolean;
  total: number;
  betHistory: BetslipHistoryItems;
  loadMore: () => Promise<void>;
}

export const BetslipHistoryMobile = memo(
  ({ betHistory, loadMore, total, isLoading }: BetslipHistoryMobileProps) => {
    const { localized } = useTranslation();

    if (isLoading) {
      return (
        <Box flexCol alignCenter gap={2} css={{ paddingTop: '32px' }}>
          <Loader css={{ color: '$grayMedium', width: '$6', height: '$6' }} />
          <Text color="grayMedium" level="12-20">
            {localized('betslipHistory.loading')}
          </Text>
        </Box>
      );
    }

    if (!betHistory.length) {
      return <NoBets />;
    }

    return (
      <BetslipHistoryMobileContent
        betHistory={betHistory}
        loadMore={loadMore}
        total={total}
      />
    );
  },
);
