import { useEffect, useState } from 'react';

import { Box } from '~components/atoms/Box';
import {
  StyledTabsContent,
  StyledTabsRoot,
} from '~components/atoms/Tabs/styled.components';
import { useBetslipHistory } from '~components/molecules/BetslipHistorySection/Sports/useBetslipHistory';
import { USER_BETSLIP_HISTORY_TABS } from '~components/molecules/UserProfile/components/UserProfileDialog';
import useIsInIframe from '~hooks/useIsInIframe';
import { useAppDispatch, useAppSelector } from '~store';
import { setContentTab } from '~store/slices/personalDetailsSlice';
import { ContentsMap } from '~types/general';

import { BETSLIP_HISTORY_TABS_LIST } from '../constants';
import { JackpotsBetsHistoryMobile } from '../Jackpot/JackpotsBetsHistoryMobile/JackpotsBetsHistoryMobile';
import { BetslipHistoryFiltersMobile } from '../Sports/BetslipHistoryFiltersMobile';
import { BetslipHistoryMobile } from '../Sports/BetslipHistoryMobile';
import { useBetslipHistoryFilters } from '../Sports/useBetslipHistoryFilters';

import { BetslipHistoryTabsMobile } from './BetslipHistoryTabsMobile';

export const BetslipHistoryWrapperMobile = () => {
  const dispatch = useAppDispatch();
  const { contentTab } = useAppSelector((state) => state.personalDetails);
  const [showFilters, setShowFilters] = useState(false);

  const isInIframe = useIsInIframe();
  const { loadBetslipHistory, isLoading, total, loadMore, betHistory } =
    useBetslipHistory();
  const betslipHistoryFiltersParams = useBetslipHistoryFilters({
    loadBetslipHistory,
  });

  const selectedContentTab = BETSLIP_HISTORY_TABS_LIST.includes(
    contentTab as USER_BETSLIP_HISTORY_TABS,
  )
    ? contentTab
    : USER_BETSLIP_HISTORY_TABS.SPORTS;

  const tabsContentMap: ContentsMap = {
    [USER_BETSLIP_HISTORY_TABS.SPORTS]: (
      <BetslipHistoryMobile
        isLoading={isLoading}
        total={total}
        loadMore={loadMore}
        betHistory={betHistory}
      />
    ),
    [USER_BETSLIP_HISTORY_TABS.JACKPOT]: <JackpotsBetsHistoryMobile />,
  };

  useEffect(() => {
    loadBetslipHistory({});
  }, []);

  return (
    <StyledTabsRoot
      onValueChange={(value) =>
        dispatch(setContentTab(value as USER_BETSLIP_HISTORY_TABS))
      }
      value={selectedContentTab as string}
      fullHeight
    >
      <Box flexCol gap={2} fullHeight>
        {isInIframe ? null : (
          <BetslipHistoryTabsMobile
            setShowFilters={setShowFilters}
            disabledFilters={
              selectedContentTab === USER_BETSLIP_HISTORY_TABS.JACKPOT
            }
            betslipHistoryFiltersParams={betslipHistoryFiltersParams}
          />
        )}
        <StyledTabsContent
          value={selectedContentTab as USER_BETSLIP_HISTORY_TABS}
          css={{ paddingTop: '40px' }} // Because of the sticky header we need to add padding to the content
        >
          {showFilters ? (
            <BetslipHistoryFiltersMobile
              setShowFilters={setShowFilters}
              betslipHistoryFiltersParams={betslipHistoryFiltersParams}
            />
          ) : (
            <StyledTabsContent
              value={selectedContentTab as USER_BETSLIP_HISTORY_TABS}
            >
              {tabsContentMap[selectedContentTab as USER_BETSLIP_HISTORY_TABS]}
            </StyledTabsContent>
          )}
        </StyledTabsContent>
      </Box>
    </StyledTabsRoot>
  );
};
