import * as DropdownUI from '@radix-ui/react-dropdown-menu';
import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import {
  slideDownAndFade,
  slideLeftAndFade,
  slideRightAndFade,
  slideUpAndFade,
} from '~utils/keyframes';
import { zIndex } from '~utils/zIndex';

export const StyledDropdownTrigger = styled(DropdownUI.Trigger, {
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  gap: '$2',
  justifyContent: 'center',
  color: '$white',
  width: '100%',
  zIndex: 1,
});

export const StyledSettingsMobileButton = styled(Box, {
  color: 'white',
  background: '$grayDarkMain2',
  height: '2.5rem',
  width: '2.5rem',
  p: '$2',
  borderRadius: '$8',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  svg: {
    width: '1.5rem !important',
    height: '1.5rem !important',
  },
});

export const StyledDropdownMenuContent = styled(DropdownUI.Content, {
  display: 'flex',
  flexDirection: 'column',
  minWidth: '260px',
  backgroundColor: '$dropdownMenuContentBgColor',
  borderRadius: '$8',
  border: '1px solid $dropdownMenuContentBorderColor',
  animationDuration: '400ms',
  animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
  willChange: 'transform, opacity',
  zIndex: zIndex.modal,
  '&[data-state="open"]': {
    '&[data-side="top"]': { animationName: slideDownAndFade },
    '&[data-side="right"]': { animationName: slideLeftAndFade },
    '&[data-side="bottom"]': { animationName: slideUpAndFade },
    '&[data-side="left"]': { animationName: slideRightAndFade },
  },
  '@xs_sm': {
    p: '$4',
  },
});

export const StyledSettingsPopoverContent = styled(Box, {
  height: '100%',
  display: 'flex',
  borderRadius: '$8',
  flexDirection: 'column',
  width: '100%',
  '& > div:not(:last-child)': {
    borderBottom: '1px solid $grayDarker',
  },
  '@xs_sm': {
    background: '$grayDark',
    mb: '1rem',
  },
});

export const StyledSettingsPopoverItem = styled(Box, {
  padding: '8px 12px 12px',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  '& > div': {
    flexGrow: 1,
  },
});

export const StyledOddsContainer = styled(Box, {
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  rowGap: '$4',
  columnGap: '$4',
});

export const StyledCloseButton = styled('span', {
  position: 'absolute',
  top: '1rem',
  right: '1rem',
  width: '1rem',
  height: '1rem',
  color: '$grayMedium',
  svg: {
    width: '100%',
    height: '100%',
  },
  '&:hover': {
    opacity: 0.6,
  },
});

export const StyledSettingsPopoverDesktopLabel = styled(Text, {
  marginBottom: '$3',
});
