import React, { useMemo } from 'react';

import { Logo } from '~components/atoms/Logo/Logo';
import { useAppSelector } from '~store';
import { selectIsUganda } from '~store/slices/selectors/settings';

export const LogoHeaderWrapper = () => {
  const isUganda = useAppSelector(selectIsUganda);

  const logoCss = useMemo(
    () => ({
      width: 'unset',
      height: 'unset',
      '@md_lg_xl': {
        width: !isUganda ? '216px' : '264px',
        height: '64px',
      },
      '@xs_sm': {
        width: '92px',
        height: '26px',
      },
      '@xxs': {
        width: '56px',
        height: '16px',
      },
    }),
    [isUganda],
  );

  return <Logo type="header" css={logoCss} isHeader />;
};
