import { memo, useEffect, useRef, useState } from 'react';

import { SportEventItem } from '~api/sportEvent/types';
import { useLiveEventConnection } from '~components/molecules/InplayEvents/hooks/useLiveEventConnection';
import { InplayEvent } from '~components/molecules/InplayEvents/InplayEvent';
import {
  useEventsLoadingSocket,
  useEventUpdatesListener,
  useQueryParams,
} from '~hooks';
import { useAppDispatch, useAppSelector } from '~store';
import { setLoadingEventId } from '~store/slices/liveMenuSlice';
import { SportEvent } from '~types/events';
import { ACTION_TYPE } from '~utils/eventsSocketUtils';

export const InplayEventConnection = memo(() => {
  const dispatch = useAppDispatch();
  const search = useQueryParams();
  const { eventId } = search;
  const { isPrimaryDatsLoaded, sendWsMessage } = useEventsLoadingSocket();

  const { liveEventData, isEventLoaded, marketGroups, loadingEventId } =
    useAppSelector((state) => state.liveMenu);

  const [eventData, setEventData] = useState<SportEvent | null>(null);
  const eventDataRef = useRef<SportEvent | null>(null);

  const updateEventData = (newData: SportEvent | null) => {
    if (eventId !== newData?.id) return;
    setEventData(newData);
    eventDataRef.current = newData;
  };

  useEffect(() => {
    updateEventData(liveEventData);
  }, [liveEventData, eventId]);

  useLiveEventConnection({ loaded: !!eventData });

  useEventUpdatesListener({
    event: eventDataRef.current as SportEventItem,
    onUpdate: updateEventData,
  });

  useEffect(() => {
    if (eventId && isPrimaryDatsLoaded) {
      dispatch(setLoadingEventId(eventId));
      sendWsMessage(ACTION_TYPE.GET_IN_PLAY_EVENT, { Id: eventId });
    }
  }, [eventId, isPrimaryDatsLoaded]);

  return (
    <InplayEvent
      loadingEventId={loadingEventId}
      eventData={eventData}
      isEventLoaded={isEventLoaded}
      marketGroups={marketGroups}
    />
  );
});
